import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/working_dir/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Playground, Props } from 'docz';
import Component from 'react-component-component';
import { Flag } from '../../src';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "flag"
    }}>{`Flag`}</h1>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`import Flag from '@mfrm/mfcl/Flag'
`}</code></pre>
    <h2 {...{
      "id": "description"
    }}>{`Description`}</h2>
    <p>{`Displays a colored flag with text within its parent component`}</p>
    <h2 {...{
      "id": "properties"
    }}>{`Properties`}</h2>
    <Props of={Flag} mdxType="Props" />
    <h2 {...{
      "id": "basic-usage"
    }}>{`Basic Usage`}</h2>
    <Playground __position={1} __code={'{/* Example container */}\n<div style={{ height: \'250px\', width: \'250px\', border: \'1px solid gray\' }}>\n  <Flag text={\'50% OFF\'} />\n</div>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Component,
      Flag,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  {/* Example container */}
  <div style={{
        height: '250px',
        width: '250px',
        border: '1px solid gray'
      }}>
    <Flag text={'50% OFF'} mdxType="Flag" />
  </div>
    </Playground>
    <h2 {...{
      "id": "customize-color"
    }}>{`Customize Color`}</h2>
    <Playground __position={2} __code={'{/* Ex. container */}\n<div\n  style={{\n    height: \'250px\',\n    width: \'250px\',\n    border: \'1px solid gray\',\n    margin: \'6px\',\n    display: \'inline-block\',\n  }}\n>\n  {/* Green Flag */}\n  <Flag text={\'10% OFF\'} backgroundColor={\'#119e16\'} />\n</div>\n{/* Ex. container */}\n<div\n  style={{\n    height: \'250px\',\n    width: \'250px\',\n    border: \'1px solid gray\',\n    margin: \'6px\',\n    display: \'inline-block\',\n  }}\n>\n  {/* Blue Flag */}\n  <Flag text={\'60% OFF\'} backgroundColor={\'#0094ff\'} />\n</div>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Component,
      Flag,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  {/* Ex. container */}
  <div style={{
        height: '250px',
        width: '250px',
        border: '1px solid gray',
        margin: '6px',
        display: 'inline-block'
      }}>
    {/* Green Flag */}
    <Flag text={'10% OFF'} backgroundColor={'#119e16'} mdxType="Flag" />
  </div>
  {/* Ex. container */}
  <div style={{
        height: '250px',
        width: '250px',
        border: '1px solid gray',
        margin: '6px',
        display: 'inline-block'
      }}>
    {/* Blue Flag */}
    <Flag text={'60% OFF'} backgroundColor={'#0094ff'} mdxType="Flag" />
  </div>
    </Playground>
    <h2 {...{
      "id": "customize-size"
    }}>{`Customize Size`}</h2>
    <Playground __position={3} __code={'<div style={{ height: \'300px\', width: \'300px\', border: \'1px solid gray\' }}>\n  <Flag\n    text={\'BIG BIG BIG BIG BIG BIG BIG BIG SALE 100% OFF\'}\n    height={\'200px\'}\n    width={\'275px\'}\n  />\n</div>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Component,
      Flag,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <div style={{
        height: '300px',
        width: '300px',
        border: '1px solid gray'
      }}>
    <Flag text={'BIG BIG BIG BIG BIG BIG BIG BIG SALE 100% OFF'} height={'200px'} width={'275px'} mdxType="Flag" />
  </div>
    </Playground>
    <h2 {...{
      "id": "without-container"
    }}>{`Without Container`}</h2>
    <Playground __position={4} __code={'<Flag text={\'50% OFF\'} withoutContainer={true} />'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Component,
      Flag,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Flag text={'50% OFF'} withoutContainer={true} mdxType="Flag" />
    </Playground>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      